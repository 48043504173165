export const defaultConfig = {
	tileLayer: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
	attribution: "© OpenStreetMap contributors",
	initialView: [50.9, 14.25],
	initialZoom: 12,
	controls: {
		layers: true,
		zoom: true,
		draw: false,
	},
};
