// resources/js/mapengine/controls/LayerFilterControl.js

import L from "leaflet";

export default class LayerFilterControl extends L.Control {
	constructor(options) {
		super(options);
		this.options = L.Util.extend(
			{
				position: "topright",
				filterTitle: "Filter Layers",
				containerClass: "map-layer-filter-container",
				collapsed: false,
				autoZIndex: false,
			},
			options,
		);

		this.layerManager = options.layerManager;
		this._renderedHtml = options.renderedHtml || "";
	}

	onAdd(map) {
		this._map = map;
		this._container = L.DomUtil.create("div", this.options.containerClass);

		// Disable click propagation so map doesn't receive the events
		L.DomEvent.disableClickPropagation(this._container);
		L.DomEvent.disableScrollPropagation(this._container);

		// Add the pre-rendered HTML from Twig
		if (this._renderedHtml) {
			this._container.innerHTML = this._renderedHtml;
			this._initializeEvents();
		} else {
			this._container.innerHTML =
				'<div class="map-layer-filter-error">Layer filter template not found</div>';
		}

		return this._container;
	}

	_initializeEvents() {
		// Find all layer checkboxes
		const checkboxes = this._container.querySelectorAll(".layer-checkbox");
		checkboxes.forEach((checkbox) => {
			checkbox.addEventListener(
				"change",
				this._handleLayerToggle.bind(this),
			);
		});

		// Find all layer names with children to make them collapsible
		const layerItems = this._container.querySelectorAll(".layer-item");
		layerItems.forEach((item) => {
			const toggleIcon = item.querySelector(".layer-toggle-icon");
			if (toggleIcon) {
				item.querySelector(".layer-name").addEventListener(
					"click",
					(e) => {
						e.preventDefault();
						this._toggleLayerCollapse(item);
					},
				);

				toggleIcon.addEventListener("click", (e) => {
					e.preventDefault();
					e.stopPropagation();
					this._toggleLayerCollapse(item);
				});
			}
		});

		// Select/Deselect all buttons
		const selectAllBtn = this._container.querySelector(
			".layer-action.select-all",
		);
		if (selectAllBtn) {
			selectAllBtn.addEventListener("click", this._selectAll.bind(this));
		}

		const deselectAllBtn = this._container.querySelector(
			".layer-action.deselect-all",
		);
		if (deselectAllBtn) {
			deselectAllBtn.addEventListener(
				"click",
				this._deselectAll.bind(this),
			);
		}
	}

	_handleLayerToggle(e) {
		const checkbox = e.target;
		const layerName = checkbox.dataset.layer;
		const checked = checkbox.checked;

		if (this.layerManager) {
			// Toggle the layer in the LayerManager
			this.layerManager.toggleLayer(layerName, checked);

			// Toggle child checkboxes if this is a parent layer
			this._toggleChildCheckboxes(layerName, checked);
		}
	}

	_toggleChildCheckboxes(layerName, checked) {
		if (!this.layerManager || !this.layerManager.categoryHierarchy) return;

		const children = this.layerManager.categoryHierarchy[layerName] || [];

		children.forEach((childName) => {
			// Update checkbox state
			const childCheckbox = this._container.querySelector(
				`.layer-checkbox[data-layer="${childName}"]`,
			);
			if (childCheckbox) {
				childCheckbox.checked = checked;
			}

			// Recursively update child layers
			this._toggleChildCheckboxes(childName, checked);
		});
	}

	_toggleLayerCollapse(layerItem) {
		layerItem.classList.toggle("collapsed");
	}

	_selectAll() {
		const checkboxes = this._container.querySelectorAll(".layer-checkbox");
		checkboxes.forEach((checkbox) => {
			checkbox.checked = true;
			this.layerManager.toggleLayer(checkbox.dataset.layer, true);
		});
	}

	_deselectAll() {
		const checkboxes = this._container.querySelectorAll(".layer-checkbox");
		checkboxes.forEach((checkbox) => {
			checkbox.checked = false;
			this.layerManager.toggleLayer(checkbox.dataset.layer, false);
		});
	}
}
