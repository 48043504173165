import L from "leaflet";
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";

export default class LocationField {
	/**
	 * Constructor for the LocationField
	 * @param {string|HTMLElement} container - Container selector or element for the field (not just the map)
	 * @param {object} config - Configuration options
	 */
	constructor(container, config = {}) {
		/** @private @type {HTMLElement} */
		this.fieldContainer =
			typeof container === "string"
				? document.getElementById(container)
				: container;

		/** @private @type {HTMLElement} */
		this.mapContainer = this.fieldContainer.querySelector(
			".render-leaflet-map",
		);

		if (!this.mapContainer) {
			console.error(
				"Map container element not found within the field container.",
			);
			return;
		}

		/** @private @type {string} */
		this.mapId = this.mapContainer.getAttribute("id");

		if (!this.mapId) {
			console.error("Map container must have an ID.");
			return;
		}

		this.config = config;
		/** @private @type {string} */
		this.hiddenInputId = config.inputId || `${this.mapId}-input`;
		/** @private @type {L.Map} */
		this.map = L.map(this.mapId).setView(
			config.initialView || [50.9, 14.25],
			config.initialZoom || 12,
		);
		/** @private @type {L.FeatureGroup} */
		this.drawnItems = L.featureGroup().addTo(this.map);

		// Find POI fields - use proper selectors based on your HTML structure
		this.poiTitleField =
			this.fieldContainer.querySelector("#fields-poi-title");
		this.poiDescriptionField = this.fieldContainer.querySelector(
			"#fields-poi-description",
		);

		this.init();
	}

	/**
	 * Initialize the location field
	 */
	init() {
		this.loadExistingGeometry();
		this.addTileLayer();
		this.addGeomanControls();
		this.setupEventListeners();
		this.setupInputEventListeners();
		this.fixLeafletPaneStyling();
	}

	/**
	 * Fixes leaflet pane styling issues
	 */
	fixLeafletPaneStyling() {
		const mapPane = this.mapContainer.querySelector(
			".leaflet-pane.leaflet-map-pane",
		);
		if (mapPane) {
			mapPane.classList.remove("leaflet-pane");
		}
	}

	/**
	 * Adds the OpenStreetMap tile layer
	 */
	addTileLayer() {
		L.tileLayer(
			this.config.tileLayer ||
				"https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
			{
				attribution:
					this.config.attribution || "© OpenStreetMap contributors",
			},
		).addTo(this.map);
	}

	/**
	 * Loads existing GeoJSON geometry from the hidden input
	 */
	loadExistingGeometry() {
		const geoJsonString = this.getHiddenInputValue();
		if (!geoJsonString) return;

		try {
			const geoData = JSON.parse(geoJsonString);
			this.renderGeoJson(geoData);
			this.populateFields(geoData);
		} catch (error) {
			console.error("Failed to parse GeoJSON from hidden input:", error);
		}
	}

	/**
	 * Gets the value from the hidden input field
	 * @returns {string|null} Value from the hidden input
	 */
	getHiddenInputValue() {
		const hiddenInput = this.fieldContainer.querySelector(
			`#${this.hiddenInputId}`,
		);
		if (!hiddenInput) {
			console.warn(
				`No hidden input found with ID: ${this.hiddenInputId}`,
			);
			return null;
		}
		return hiddenInput.value;
	}

	/**
	 * Renders GeoJSON on the map
	 * @param {object} geoData - GeoJSON data to render
	 */
	renderGeoJson(geoData) {
		this.drawnItems.clearLayers();
		if (geoData && geoData.features && geoData.features.length) {
			L.geoJSON(geoData, {
				onEachFeature: (feature, layer) => {
					if (feature.properties) {
						let popupContent = `<b>${feature.properties.title || ""}</b><br>${feature.properties.description || ""}`;
						layer.bindPopup(popupContent);
					}
					this.attachLayerEvents(layer);
					this.drawnItems.addLayer(layer);
				},
			});

			if (this.drawnItems.getLayers().length > 0) {
				this.map.fitBounds(this.drawnItems.getBounds());
			}
		}
	}

	/**
	 * Adds the Leaflet-Geoman drawing controls
	 */
	addGeomanControls() {
		this.map.pm.addControls({
			position: "topleft",
			editControls: true,
			drawMarker: true,
			drawPolygon: true,
			drawPolyline: true,
			removalMode: true,
			drawCircle: true,
			drawCircleMarker: false,
			drawText: false,
			editMode: true,
		});
	}

	/**
	 * Sets up event listeners for map interactions
	 */
	setupEventListeners() {
		this.map.on("pm:create", (e) => {
			const layer = e.layer;
			this.attachLayerEvents(layer);
			this.drawnItems.addLayer(layer);
			this.updateHiddenInput();
		});

		this.map.on("pm:remove", (e) => {
			const layer = e.layer;
			this.drawnItems.removeLayer(layer);
			this.updateHiddenInput();
		});
	}

	/**
	 * Sets up event listeners for POI fields
	 */
	setupInputEventListeners() {
		if (this.poiTitleField) {
			this.poiTitleField.addEventListener("input", () =>
				this.updateHiddenInput(),
			);
		} else {
			console.warn("POI title field not found");
		}

		if (this.poiDescriptionField) {
			this.poiDescriptionField.addEventListener("input", () =>
				this.updateHiddenInput(),
			);
		} else {
			console.warn("POI description field not found");
		}
	}

	/**
	 * Attaches events to a layer
	 * @param {L.Layer} layer - Layer to attach events to
	 */
	attachLayerEvents(layer) {
		if (!layer) return;
		const events = ["pm:edit", "pm:remove", "pm:dragend"];
		events.forEach((event) => {
			layer.off(event);
			layer.on(event, () => this.updateHiddenInput());
		});
	}

	/**
	 * Updates the hidden input with current GeoJSON data
	 */
	updateHiddenInput() {
		let geoJson = this.drawnItems.toGeoJSON();

		// Get title and description
		const title = this.poiTitleField?.value || "";
		const description = this.poiDescriptionField?.value || "";

		// Add to properties
		geoJson.features.forEach((feature) => {
			feature.properties = {
				title: title,
				description: description,
			};
		});

		const dataString = JSON.stringify(geoJson);
		const hiddenInput = this.fieldContainer.querySelector(
			`#${this.hiddenInputId}`,
		);

		if (hiddenInput) {
			hiddenInput.value = dataString;
		} else {
			console.warn(
				`Hidden input with ID ${this.hiddenInputId} not found`,
			);
		}
	}

	/**
	 * Populates POI fields from GeoJSON data
	 * @param {object} geoData - GeoJSON data
	 */
	populateFields(geoData) {
		if (geoData && geoData.features && geoData.features.length > 0) {
			const feature = geoData.features[0]; // Assuming only one feature or all have same properties
			if (feature.properties) {
				if (this.poiTitleField) {
					this.poiTitleField.value = feature.properties.title || "";
				}
				if (this.poiDescriptionField) {
					this.poiDescriptionField.value =
						feature.properties.description || "";
				}
			}
		}
	}
}
