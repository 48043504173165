export const defaultConfig = {
	tileLayer: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
	attribution: "© OpenStreetMap contributors",
	initialView: [51.505, -0.09],
	initialZoom: 10,
	controls: {
		layers: true,
		zoom: true,
		draw: false,
	},
	showParentLayers: false,
	detourClasses: {
		path: 'detour-path',
		marker: 'detour-marker',
		popup: 'detour-popup',
		hidden: 'detour-hidden'
	}
};
