import MapEngine from "./core/MapEngine";
import LocationField from "./fields/LocationField";
import LayerFilterControl from "./controls/LayerFilterControl";
import LayerPreview from "./controls/LayerPreview";
import { defaultConfig } from "./config";
import "../scss/mapengine.scss";

// Export main classes
export { MapEngine, LocationField, LayerFilterControl, LayerPreview };

// Factory functions for easier initialization
export function createMap(container, options = {}, geoJSON = null) {
	const config = { ...defaultConfig, ...options };

	if (geoJSON) {
		config.geoJsonData = geoJSON;
	}

	return new MapEngine(container, config);
}

export function createLocationField(container, options = {}) {
	const config = { ...defaultConfig, ...options };
	return new LocationField(container, config);
}

export function createLayerPreview(blockId, mapId, layerNames) {
	return new LayerPreview(blockId, mapId, layerNames);
}

// Add to window for direct script tag access
window.MapEngine = {
	MapEngine,
	LocationField,
	LayerFilterControl,
	LayerPreview,
	createMap,
	createLocationField,
	createLayerPreview,
};
