/**
 * Layer preview control for showing/hiding layers on hover
 * @class
 * @example
 * new LayerPreview('layer-preview', 'map', ['layer1', 'layer2']);
 */
export default class LayerPreview {
	/**
	 * Create a new LayerPreview instance
	 * @param {string} blockId - The ID of the block container
	 * @param {string} mapId - The ID of the map instance
	 * @param {Array<string>} layerNames - Array of layer names
	 */
	constructor(blockId, mapId, layerNames) {
		this.blockId = blockId;
		this.mapId = mapId;
		this.layerNames = layerNames;
		this.blockContainer = document.getElementById(blockId);
		this.mapInstance = window.mapInstances[mapId];

		// Configure delays in ms
		this.hoverDelay = 150;
		this.leaveDelay = 500;

		// Timeout tracking
		this.hoverTimeoutId = null;
		this.leaveTimeoutId = null;
		this.activeLayerName = null;
		this.pendingLayerName = null;

		if (!this.mapInstance) {
			console.error(`Map instance ${mapId} not found`);
			return;
		}

		this.init();
	}

	/**
	 * Initialize the layer preview
	 */
	init() {
		// Find all layer links
		this.layerLinks = this.blockContainer.querySelectorAll(".layer-link");

		// Add event listeners
		this.addEventListeners();

		// Initialize with all layers visible
		this.showAllLayers();
	}

	/**
	 * Add event listeners to layer links
	 */
	addEventListeners() {
		this.layerLinks.forEach((link) => {
			link.addEventListener(
				"mouseenter",
				this.handleLayerHover.bind(this),
			);
			link.addEventListener(
				"mouseleave",
				this.handleLayerLeave.bind(this),
			);
		});
	}

	/**
	 * Handle layer link hover with delay
	 * @param {Event} event - Mouse event
	 */
	handleLayerHover(event) {
		const link = event.currentTarget;
		const layerName = link.getAttribute("data-layer-name");
		this.pendingLayerName = layerName;

		// Clear any existing hover timeout
		if (this.hoverTimeoutId) {
			clearTimeout(this.hoverTimeoutId);
		}

		// Clear any leave timeout since we're hovering again
		if (this.leaveTimeoutId) {
			clearTimeout(this.leaveTimeoutId);
			this.leaveTimeoutId = null;
		}

		// Only set a timeout if the layer is different from the currently active one
		if (this.activeLayerName !== layerName) {
			this.hoverTimeoutId = setTimeout(() => {
				// Only trigger if this is still the pending layer
				if (this.pendingLayerName === layerName) {
					this.showOnlyLayer(layerName);
					this.activeLayerName = layerName;
				}
				this.hoverTimeoutId = null;
			}, this.hoverDelay);
		}
	}

	/**
	 * Handle layer link mouse leave with delay
	 */
	handleLayerLeave() {
		// Clear hover timeout since we're leaving
		if (this.hoverTimeoutId) {
			clearTimeout(this.hoverTimeoutId);
			this.hoverTimeoutId = null;
		}

		// Clear any existing leave timeout
		if (this.leaveTimeoutId) {
			clearTimeout(this.leaveTimeoutId);
		}

		// Set leave timeout
		this.leaveTimeoutId = setTimeout(() => {
			// Only reset if we're not hovering over anything new
			if (!this.hoverTimeoutId) {
				this.showAllLayers();
				this.activeLayerName = null;
				this.pendingLayerName = null;
			}
			this.leaveTimeoutId = null;
		}, this.leaveDelay);
	}

	/**
	 * Show only a specific layer
	 * @param {string} layerName - Layer name to show
	 */
	showOnlyLayer(layerName) {
		// First hide all layers
		this.layerNames.forEach((name) => {
			const layer = this.mapInstance.layerManager.getLayer(name);
			if (layer) {
				this.mapInstance.layerManager.toggleLayer(name, false);
			}
		});

		// Then show the selected layer
		const layer = this.mapInstance.layerManager.getLayer(layerName);
		if (layer) {
			this.mapInstance.layerManager.toggleLayer(layerName, true);

			// Fit map to the visible layer
			setTimeout(() => {
				this.mapInstance.fitLayer(layerName);
			}, 100);
		}
	}

	/**
	 * Show all layers
	 */
	showAllLayers() {
		this.layerNames.forEach((name) => {
			const layer = this.mapInstance.layerManager.getLayer(name);
			if (layer) {
				this.mapInstance.layerManager.toggleLayer(name, true);
			}
		});

		// Fit map to all layers
		setTimeout(() => {
			this.mapInstance.fitAllLayers();
		}, 100);
	}
}
