import L from "leaflet";

export default class LayerManager {
	constructor(map, config) {
		this.map = map;
		this.config = config;
		this.layerGroups = {};
		this.layerControls = {};
		this.categoryHierarchy = {};

		if (this.config.controls && this.config.controls.layers) {
			this.layerControl = L.control
				.layers(
					{},
					{},
					{
						position: "topright",
						collapsed: false,
					},
				)
				.addTo(this.map);
		}
	}

	/**
	 * Load GeoJSON data into map layers
	 * @param {Object|Array} data - GeoJSON data structure to load
	 * @returns {void}
	 */
	loadGeoJSON(data) {
		if (!data) return;

		// Handle different data structures
		if (typeof data === "object" && !Array.isArray(data)) {
			// Process hierarchical structure
			Object.entries(data).forEach(([layerName, layerData]) => {
				this.processLayer(layerName, layerData);
			});
		}

		this.adjustMapBounds();
	}

	/**
	 * Process a layer and its associated data
	 * @param {string} layerName - Name of the layer
	 * @param {Object|Array|string} layerData - Layer GeoJSON data
	 * @param {string|null} parentName - Parent layer name if this is a child layer
	 * @returns {void}
	 */
	processLayer(layerName, layerData, parentName = null) {
		// Create layer group
		const layerGroup = L.layerGroup().addTo(this.map);
		this.layerGroups[layerName] = layerGroup;

		// Register hierarchy
		if (parentName) {
			if (!this.categoryHierarchy[parentName]) {
				this.categoryHierarchy[parentName] = [];
			}
			this.categoryHierarchy[parentName].push(layerName);
		}

		// Process GeoJSON features
		if (Array.isArray(layerData)) {
			// Array of GeoJSON features or strings
			layerData.forEach((geoData) => {
				this.addGeoJSONToLayer(geoData, layerName, layerGroup);
			});
		} else if (typeof layerData === "string") {
			// Single GeoJSON string - try to parse it
			try {
				this.addGeoJSONToLayer(layerData, layerName, layerGroup);
			} catch (e) {
				console.error(
					`Failed to parse GeoJSON string for layer ${layerName}:`,
					e,
				);
			}
		} else if (layerData && typeof layerData === "object") {
			// Check if it's a GeoJSON object or nested layers
			if (
				layerData.type === "FeatureCollection" ||
				layerData.type === "Feature"
			) {
				// It's a GeoJSON object
				this.addGeoJSONToLayer(layerData, layerName, layerGroup);
			} else if (layerData.__data) {
				// Special case for our format with nested data
				// Only add the parent's data if it doesn't have child layers or if explicitly configured
				const hasChildLayers = Object.keys(layerData).some(
					(key) => key !== "__data",
				);

				if (!hasChildLayers || this.config.showParentLayers === true) {
					this.addGeoJSONToLayer(
						layerData.__data,
						layerName,
						layerGroup,
					);
				} else {
					// Remove the layer from map if it has children but shouldn't display itself
					this.map.removeLayer(layerGroup);
				}

				// Process other keys as child layers (skipping __data)
				Object.entries(layerData).forEach(([childKey, childData]) => {
					if (childKey !== "__data") {
						this.processLayer(childKey, childData, layerName);
					}
				});
			} else {
				// It's nested layers
				Object.entries(layerData).forEach(
					([childLayerName, childLayerData]) => {
						this.processLayer(
							childLayerName,
							childLayerData,
							layerName,
						);
					},
				);
			}
		}

		// Add to layer control if enabled
		if (this.layerControl) {
			this.layerControl.addOverlay(layerGroup, layerName);
		}
	}

	/**
	 * Add GeoJSON data to a specific layer group
	 * @param {Object|string} geoData - GeoJSON data to add
	 * @param {string} layerName - Name of the layer to add data to
	 * @param {L.LayerGroup} layerGroup - Leaflet LayerGroup instance
	 * @returns {L.GeoJSON|null} - Created GeoJSON layer or null if failed
	 */
	addGeoJSONToLayer(geoData, layerName, layerGroup) {
		try {
			// Make sure geoData is an object, not a string
			const geoJsonData =
				typeof geoData === "string" ? JSON.parse(geoData) : geoData;

			// Get the color for this layer
			const color =
				this.config.colors && this.config.colors[layerName]
					? this.config.colors[layerName]
					: "#000000";

			// Validate GeoJSON minimally before adding to map
			if (
				!geoJsonData ||
				(geoJsonData.type !== "FeatureCollection" &&
					geoJsonData.type !== "Feature") ||
				(geoJsonData.type === "FeatureCollection" &&
					!Array.isArray(geoJsonData.features))
			) {
				return null;
			}

			const layer = L.geoJSON(geoJsonData, {
				style: { color },
				onEachFeature: (feature, layer) => {
					if (feature.properties) {
						// Only bind popup if title or description exists and are non-empty
						if (
							feature.properties.title ||
							feature.properties.description
						) {
							const title = feature.properties.title || "";
							const description =
								feature.properties.description || "";
							if (title || description) {
								const popupContent = `<b>${title}</b><br>${description}`;
								layer.bindPopup(popupContent);
							}
						}
					}
				},
			});

			// Add to layer group
			layer.addTo(layerGroup);
			return layer;
		} catch (error) {
			console.error(
				`Error processing GeoJSON for layer ${layerName}:`,
				error,
			);
			console.error("Problematic GeoJSON:", geoData);
			return null;
		}
	}

	/**
	 * Adjust the map bounds to fit all visible layers
	 * @returns {void}
	 */
	adjustMapBounds() {
		const bounds = this.getAllLayersBounds();
		if (bounds && bounds.isValid()) {
			this.map.fitBounds(bounds);
		}
	}

	/**
	 * Get bounds for a specific layer by name
	 * @param {string} layerName - Name of the layer to get bounds for
	 * @returns {L.LatLngBounds|null} - Bounds of the layer or null if invalid/not found
	 */
	getLayerBounds(layerName) {
		const layer = this.getLayer(layerName);
		if (!layer) {
			return null;
		}

		const bounds = L.latLngBounds();
		let hasValidGeometry = false;

		layer.eachLayer((sublayer) => {
			// Handle different geometry types
			if (sublayer.getBounds) {
				// For polygons, polylines, etc.
				bounds.extend(sublayer.getBounds());
				hasValidGeometry = true;
			} else if (sublayer.getLatLng) {
				// For markers, circles, etc.
				bounds.extend(sublayer.getLatLng());
				hasValidGeometry = true;
			} else if (sublayer.getLatLngs) {
				// For some multigeometries
				bounds.extend(sublayer.getLatLngs());
				hasValidGeometry = true;
			}
		});

		return hasValidGeometry ? bounds : null;
	}

	/**
	 * Get bounds that encompass all layers in the map
	 * @returns {L.LatLngBounds|null} - Combined bounds of all layers or null if no valid layers
	 */
	getAllLayersBounds() {
		const bounds = L.latLngBounds();
		let hasLayers = false;

		Object.values(this.layerGroups).forEach((layerGroup) => {
			if (layerGroup.getLayers().length > 0) {
				hasLayers = true;
				layerGroup.eachLayer((layer) => {
					if (layer.getBounds) {
						bounds.extend(layer.getBounds());
					}
				});
			}
		});

		return hasLayers ? bounds : null;
	}

	/**
	 * Get a layer by its name
	 * @param {string} layerName - Name of the layer to retrieve
	 * @returns {L.LayerGroup|null} - Layer group or null if not found
	 */
	getLayer(layerName) {
		return this.layerGroups[layerName] || null;
	}

	/**
	 * Toggle visibility of a layer by name
	 * @param {string} layerName - Name of the layer to toggle
	 * @param {boolean} visible - Whether the layer should be visible
	 * @returns {void}
	 */
	toggleLayer(layerName, visible) {
		const layer = this.getLayer(layerName);
		if (layer) {
			if (visible) {
				this.map.addLayer(layer);
			} else {
				this.map.removeLayer(layer);
			}

			// Also toggle children
			this.toggleChildLayers(layerName, visible);
		}
	}

	/**
	 * Toggle visibility of all child layers
	 * @param {string} parentName - Name of the parent layer
	 * @param {boolean} visible - Whether the child layers should be visible
	 * @returns {void}
	 */
	toggleChildLayers(parentName, visible) {
		const children = this.categoryHierarchy[parentName] || [];
		children.forEach((childName) => {
			this.toggleLayer(childName, visible);
		});
	}
}
