import L from "leaflet";

export default class LayerManager {
	constructor(map, config) {
		this.map = map;
		this.config = config;
		this.layerGroups = {};
		this.layerControls = {};
		this.categoryHierarchy = {};
		// Track detours and their associated warnings
		this.detours = {};
		this.warnings = {};

		if (this.config.controls && this.config.controls.layers) {
			this.layerControl = L.control
				.layers(
					{},
					{},
					{
						position: "topright",
						collapsed: false,
					},
				)
				.addTo(this.map);
		}
	}

	/**
	 * Load GeoJSON data into map layers
	 * @param {Object|Array} data - GeoJSON data structure to load
	 * @returns {void}
	 */
	loadGeoJSON(data) {
		if (!data) return;

		// Handle different data structures
		if (typeof data === "object" && !Array.isArray(data)) {
			// Process hierarchical structure
			Object.entries(data).forEach(([layerName, layerData]) => {
				this.processLayer(layerName, layerData);
			});
		}

		this.adjustMapBounds();
	}

	/**
	 * Process a layer and its associated data
	 * @param {string} layerName - Name of the layer
	 * @param {Object|Array|string} layerData - Layer GeoJSON data
	 * @param {string|null} parentName - Parent layer name if this is a child layer
	 * @returns {void}
	 */
	processLayer(layerName, layerData, parentName = null) {
		// Create layer group
		const layerGroup = L.layerGroup().addTo(this.map);
		this.layerGroups[layerName] = layerGroup;

		// Register hierarchy
		if (parentName) {
			if (!this.categoryHierarchy[parentName]) {
				this.categoryHierarchy[parentName] = [];
			}
			this.categoryHierarchy[parentName].push(layerName);
		}

		this.processLayerData(layerData, layerName, layerGroup, parentName);

		// Add to layer control if enabled
		if (this.layerControl) {
			this.layerControl.addOverlay(layerGroup, layerName);
		}
	}

	/**
	 * Process the layer data based on its type
	 * @param {Object|Array|string} layerData - Layer GeoJSON data
	 * @param {string} layerName - Name of the layer
	 * @param {L.LayerGroup} layerGroup - Leaflet LayerGroup instance
	 * @param {string|null} parentName - Parent layer name
	 */
	processLayerData(layerData, layerName, layerGroup, parentName) {
		if (Array.isArray(layerData)) {
			// Array of GeoJSON features or strings
			layerData.forEach((geoData) => {
				this.addGeoJSONToLayer(geoData, layerName, layerGroup);
			});
		} else if (typeof layerData === "string") {
			// Single GeoJSON string - try to parse it
			try {
				this.addGeoJSONToLayer(layerData, layerName, layerGroup);
			} catch (e) {
				console.error(
					`Failed to parse GeoJSON string for layer ${layerName}:`,
					e,
				);
			}
		} else if (layerData && typeof layerData === "object") {
			this.processObjectLayerData(layerData, layerName, layerGroup, parentName);
		}
	}

	/**
	 * Process object-type layer data
	 * @param {Object} layerData - Layer GeoJSON data
	 * @param {string} layerName - Name of the layer
	 * @param {L.LayerGroup} layerGroup - Leaflet LayerGroup instance
	 */
	processObjectLayerData(layerData, layerName, layerGroup) {
		// Check if it's a GeoJSON object or nested layers
		if (
			layerData.type === "FeatureCollection" ||
			layerData.type === "Feature"
		) {
			// It's a GeoJSON object
			this.addGeoJSONToLayer(layerData, layerName, layerGroup);
		} else if (layerData.__data) {
			// Special case for our format with nested data
			// Only add the parent's data if it doesn't have child layers or if explicitly configured
			const hasChildLayers = Object.keys(layerData).some(
				(key) => key !== "__data",
			);

			if (!hasChildLayers || this.config.showParentLayers === true) {
				this.addGeoJSONToLayer(
					layerData.__data,
					layerName,
					layerGroup,
				);
			} else {
				// Remove the layer from map if it has children but shouldn't display itself
				this.map.removeLayer(layerGroup);
			}

			// Process other keys as child layers (skipping __data)
			Object.entries(layerData).forEach(([childKey, childData]) => {
				if (childKey !== "__data") {
					this.processLayer(childKey, childData, layerName);
				}
			});
		} else {
			// It's nested layers
			Object.entries(layerData).forEach(
				([childLayerName, childLayerData]) => {
					this.processLayer(
						childLayerName,
						childLayerData,
						layerName,
					);
				},
			);
		}
	}

	/**
	 * Add GeoJSON data to a specific layer group
	 * @param {Object|string} geoData - GeoJSON data to add
	 * @param {string} layerName - Name of the layer to add data to
	 * @param {L.LayerGroup} layerGroup - Leaflet LayerGroup instance
	 * @returns {L.GeoJSON|null} - Created GeoJSON layer or null if failed
	 */
	addGeoJSONToLayer(geoData, layerName, layerGroup) {
		try {
			// Make sure geoData is an object, not a string
			const geoJsonData =
				typeof geoData === "string" ? JSON.parse(geoData) : geoData;

			// Get the color for this layer
			const color =
				this.config.colors && this.config.colors[layerName]
					? this.config.colors[layerName]
					: "#000000";

			// Check if this is a detour collection or contains detour features
			const isDetourCollection = this.isDetourCollection(geoJsonData);

			// Validate GeoJSON minimally before adding to map
			if (!this.isValidGeoJSON(geoJsonData)) {
				return null;
			}

			const layer = L.geoJSON(geoJsonData, {
				style: (feature) => this.getFeatureStyle(feature, color),
				onEachFeature: (feature, layer) => this.onEachFeature(feature, layer, layerName),
				pointToLayer: (feature, latlng) => this.createMarker(feature, latlng)
			});

			// Add to layer group with proper visibility
			this.addLayerWithProperVisibility(layer, layerGroup, isDetourCollection);
			
			return layer;
		} catch (error) {
			console.error(
				`Error processing GeoJSON for layer ${layerName}:`,
				error,
			);
			console.error("Problematic GeoJSON:", geoData);
			return null;
		}
	}

	/**
	 * Check if a GeoJSON object is a detour collection
	 * @param {Object} geoJsonData - GeoJSON data to check
	 * @returns {boolean} - True if it's a detour collection
	 */
	isDetourCollection(geoJsonData) {
		// Check for collection level property
		if (geoJsonData.properties && geoJsonData.properties.isDetourCollection) {
			return true;
		}
		
		// Check for feature level properties in a collection
		if (geoJsonData.type === "FeatureCollection" && 
			geoJsonData.features && 
			geoJsonData.features.some(f => f.properties && f.properties.isDetour)) {
			return true;
		}
		
		// Check if it's a single detour feature
		if (geoJsonData.type === "Feature" && 
			geoJsonData.properties && 
			geoJsonData.properties.isDetour) {
			return true;
		}
		
		return false;
	}

	/**
	 * Validate a GeoJSON object
	 * @param {Object} geoJsonData - GeoJSON data to validate
	 * @returns {boolean} - True if valid
	 */
	isValidGeoJSON(geoJsonData) {
		return (
			geoJsonData &&
			(geoJsonData.type === "FeatureCollection" ||
				geoJsonData.type === "Feature") &&
			(geoJsonData.type !== "FeatureCollection" ||
				Array.isArray(geoJsonData.features))
		);
	}

	/**
	 * Get style for a GeoJSON feature
	 * @param {Object} feature - GeoJSON feature
	 * @param {string} defaultColor - Default color to use if not a detour
	 * @returns {Object} - Style object
	 */
	getFeatureStyle(feature, defaultColor) {
		// Check if the feature is a detour
		const isDetour = feature.properties && feature.properties.isDetour;

		if (isDetour) {
			// Use CSS classes for detour styling
			return {
				className: this.config.detourClasses?.path
			};
		}

		// Regular feature style
		return {
			color: defaultColor,
			weight: 3,
			opacity: 0.8,
			dashArray: null, // Explicitly set to null to override any inherited value
			className: "" // No animation class
		};
	}

	/**
	 * Process each feature when adding to the map
	 * @param {Object} feature - GeoJSON feature
	 * @param {L.Layer} layer - Leaflet layer for the feature
	 */
	onEachFeature(feature, layer) {
		if (!feature.properties) return;

		const warningId = feature.properties.warningId;
		const isDetour = feature.properties.isDetour;
		const hasDetour = feature.properties.hasDetour;

		// Track relationships between warnings and detours
		this.trackWarningDetourRelationship(layer, warningId, isDetour, hasDetour);
		
		// Set initial visibility for detours
		this.setInitialDetourVisibility(layer, warningId, isDetour);

		// Add popups to features
		this.addPopupToFeature(feature, layer);
	}

	/**
	 * Track the relationship between warnings and their detours
	 * @param {L.Layer} layer - Leaflet layer
	 * @param {string} warningId - ID of the warning
	 * @param {boolean} isDetour - Whether this is a detour
	 * @param {boolean} hasDetour - Whether this warning has a detour
	 */
	trackWarningDetourRelationship(layer, warningId, isDetour, hasDetour) {
		if (!warningId) return;

		// Track warnings with detours
		if (hasDetour) {
			if (!this.warnings[warningId]) {
				this.warnings[warningId] = {
					layers: [],
					detourVisible: false
				};
			}
			this.warnings[warningId].layers.push(layer);
		}

		// Track detours
		if (isDetour) {
			if (!this.detours[warningId]) {
				this.detours[warningId] = [];
			}
			this.detours[warningId].push(layer);
		}
	}

	/**
	 * Set initial visibility for detour features
	 * @param {L.Layer} layer - Leaflet layer
	 * @param {string} warningId - ID of the warning
	 * @param {boolean} isDetour - Whether this is a detour
	 */
	setInitialDetourVisibility(layer, warningId, isDetour) {
		if (!isDetour || !warningId) return;

		// Get the hidden class
		const hiddenClass = this.config.detourClasses?.hidden;

		// Make sure the warnings object is properly initialized
		if (!this.warnings[warningId]) {
			this.warnings[warningId] = {
				layers: [],
				detourVisible: this.config.autoShowDetours || false
			};
		} else {
			// Set the initial visibility state based on config
			this.warnings[warningId].detourVisible = this.config.autoShowDetours || false;
		}

		if (this.config.autoShowDetours) {
			// In auto-show mode, make detours visible
			this.removeClassFromLayer(layer, hiddenClass);
			this.setLayerInteractivity(layer, true);
		} else {
			// Initially hide detours - they'll be toggled through UI
			this.addClassToLayer(layer, hiddenClass);
			this.setLayerInteractivity(layer, false);
		}
	}

	/**
	 * Add a class to a Leaflet layer
	 * @param {L.Layer} layer - The layer to modify
	 * @param {string} className - The class name to add
	 */
	addClassToLayer(layer, className) {
		if (layer.getElement) {
			const el = layer.getElement();
			if (el) {
				el.classList.add(className);
			}
		} else if (layer.getPath) {
			const path = layer.getPath();
			if (path) {
				path.classList.add(className);
			}
		} else if (layer.options) {
			// For layers that don't have direct DOM elements
			const currentClasses = layer.options.className || '';
			if (!currentClasses.includes(className)) {
				layer.options.className = currentClasses 
					? `${currentClasses} ${className}`
					: className;
				layer.setStyle({ className: layer.options.className });
			}
		}
	}

	/**
	 * Remove a class from a Leaflet layer
	 * @param {L.Layer} layer - The layer to modify
	 * @param {string} className - The class name to remove
	 */
	removeClassFromLayer(layer, className) {
		if (layer.getElement) {
			const el = layer.getElement();
			if (el) {
				el.classList.remove(className);
			}
		} else if (layer.getPath) {
			const path = layer.getPath();
			if (path) {
				path.classList.remove(className);
			}
		} else if (layer.options) {
			// For layers that don't have direct DOM elements
			const currentClasses = layer.options.className || '';
			if (currentClasses.includes(className)) {
				layer.options.className = currentClasses
					.split(' ')
					.filter(c => c !== className)
					.join(' ');
				layer.setStyle({ className: layer.options.className });
			}
		}
	}

	/**
	 * Create a marker for point features
	 * @param {Object} feature - GeoJSON feature
	 * @param {L.LatLng} latlng - Position for the marker
	 * @returns {L.Marker|L.CircleMarker} - Created marker
	 */
	createMarker(feature, latlng) {
		// Use a different marker style for detours
		if (feature.properties && feature.properties.isDetour) {
			return L.circleMarker(latlng, {
				className: this.config.detourClasses?.marker,
				radius: 6 // Keep radius as a direct property for proper sizing
			});
		}
		
		// Use default marker
		return L.marker(latlng);
	}

	/**
	 * Add popup to a feature
	 * @param {Object} feature - GeoJSON feature
	 * @param {L.Layer} layer - Leaflet layer
	 */
	addPopupToFeature(feature, layer) {
		if (!feature.properties) return;
		
		const title = feature.properties.title || "";
		const description = feature.properties.description || "";
		const warningId = feature.properties.warningId;
		const isDetour = feature.properties.isDetour;
		const hasDetour = feature.properties.hasDetour;
		
		if (!title && !description) return;
		
		// Create popup content
		let popupContent = this.createPopupContent(
			title, description, warningId, isDetour, hasDetour
		);
		
		const popup = L.popup({
			maxWidth: 300,
			className: isDetour ? this.config.detourClasses?.popup : '',
		});
		
		popup.setContent(popupContent);
		layer.bindPopup(popup);
		
		// Add popup event listeners after binding
		layer.on('popupopen', (e) => {
			const popupEl = e.popup.getElement();
			const toggleBtn = popupEl.querySelector('.detour-toggle-btn');
			
			if (toggleBtn) {
				// Update button text to reflect current state
				toggleBtn.textContent = this.getDetourButtonText(warningId);
				
				toggleBtn.addEventListener('click', (evt) => {
					const wId = evt.target.getAttribute('data-warning-id');
					this.toggleDetour(wId);
					// Update button text
					evt.target.textContent = this.getDetourButtonText(wId);
				});
			}
		});
	}

	/**
	 * Create a popup content HTML string
	 * @param {string} title - Title for the popup
	 * @param {string} description - Description for the popup
	 * @param {string} warningId - ID of the warning
	 * @param {boolean} isDetour - Whether this is a detour
	 * @param {boolean} hasDetour - Whether this warning has a detour
	 * @returns {string} - HTML content for the popup
	 */
	createPopupContent(title, description, warningId, isDetour, hasDetour) {
		let content = "";
		
		if (isDetour) {
			// Get translated detour labels
			const detourLabel = this.config.translations?.detour || 'Detour';
			const unknownDetourLabel = this.config.translations?.unknownDetour || 'Unknown Detour';
			
			// Detour popup content
			content = `
				<b>${title || (warningId ? detourLabel : unknownDetourLabel)}</b><br>
				${description}
			`;
			// Detour popups should not have toggle buttons
		} else {
			// Regular feature popup
			content = `<b>${title}</b><br>${description}`;
			
			// Add detour button only if this feature has a detour AND we're not in autoShowDetours mode
			if (hasDetour && warningId && !this.config.autoShowDetours) {
				content += `
					<div class="mt-2">
						<button type="button" class="detour-toggle-btn" data-warning-id="${warningId}">
							${this.getDetourButtonText(warningId)}
						</button>
					</div>
				`;
			}
		}
		
		return content;
	}

	/**
	 * Add layer to group with proper visibility
	 * @param {L.Layer} layer - Layer to add
	 * @param {L.LayerGroup} layerGroup - Layer group to add to
	 * @param {boolean} isDetourCollection - Whether this is a detour collection
	 */
	addLayerWithProperVisibility(layer, layerGroup, isDetourCollection) {
		// Add the layer to the group
		layer.addTo(layerGroup);
		
		// If this is a detour collection and we're not auto-showing detours
		if (isDetourCollection && !this.config.autoShowDetours) {
			// Get all layers recursively
			this.processLayersRecursively(layer, (sublayer) => {
				// Hide all detour layers initially
				if (sublayer.feature && 
					sublayer.feature.properties && 
					sublayer.feature.properties.isDetour) {
					// Apply the hidden class
					const hiddenClass = this.config.detourClasses?.hidden;
					this.addClassToLayer(sublayer, hiddenClass);
					// Disable interactivity
					this.setLayerInteractivity(sublayer, false);
				}
			});
		}
	}

	/**
	 * Process all layers recursively in a layer group/GeoJSON layer
	 * @param {L.Layer} layer - The layer to process
	 * @param {Function} callback - Function to call for each sublayer
	 */
	processLayersRecursively(layer, callback) {
		if (!layer) return;
		
		// Process the layer itself
		callback(layer);
		
		// If it's a layer group, process all child layers
		if (layer.eachLayer) {
			layer.eachLayer((sublayer) => {
				this.processLayersRecursively(sublayer, callback);
			});
		}
	}

	/**
	 * Set layer interactivity
	 * @param {L.Layer} layer - Layer to modify
	 * @param {boolean} interactive - Whether the layer should be interactive
	 */
	setLayerInteractivity(layer, interactive) {
		if (typeof layer.setInteractive === 'function') {
			layer.setInteractive(interactive);
		}
	}

	/**
	 * Get button text based on detour visibility state
	 * @param {string} warningId - ID of the warning
	 * @returns {string} - Button text
	 */
	getDetourButtonText(warningId) {
		const isVisible = this.warnings[warningId]?.detourVisible ?? false;
		
		// Use translated strings from config if available
		const showDetourText = this.config.translations?.showDetour || 'Show Detour';
		const hideDetourText = this.config.translations?.hideDetour || 'Hide Detour';
		
		return isVisible ? hideDetourText : showDetourText;
	}

	/**
	 * Toggle visibility of a detour for a specific warning
	 * @param {string} warningId - ID of the warning to toggle detour for
	 * @param {boolean} [zoomToDetour=true] - Whether to zoom to the detour after showing
	 * @param {boolean} [force] - Force a specific state (true = show, false = hide)
	 * @returns {boolean} - New visibility state
	 */
	toggleDetour(warningId, zoomToDetour = true, force = undefined) {
		if (!this.warnings[warningId] || !this.detours[warningId]) {
			console.warn(`No detour found for warning ${warningId}`);
			return false;
		}
		
		// Get the hidden class
		const hiddenClass = this.config.detourClasses?.hidden;
		
		// Determine the new state
		const currentState = this.warnings[warningId].detourVisible;
		const newState = force !== undefined ? force : !currentState;
		
		// Update warning state
		this.warnings[warningId].detourVisible = newState;
		
		// Update all detour layers
		this.detours[warningId].forEach(layer => {
			if (newState) {
				// Show detour by removing the hidden class
				this.removeClassFromLayer(layer, hiddenClass);
				// Re-enable interactivity
				this.setLayerInteractivity(layer, true);
			} else {
				// Hide detour by adding the hidden class
				this.addClassToLayer(layer, hiddenClass);
				// Disable interactivity
				this.setLayerInteractivity(layer, false);
			}
		});
		
		// Zoom to detour if requested
		if (newState && zoomToDetour) {
			this.zoomToDetour(warningId);
		}
		
		return newState;
	}

	/**
	 * Zoom to a specific detour
	 * @param {string} warningId - ID of the warning to zoom to detour
	 */
	zoomToDetour(warningId) {
		if (!this.detours[warningId]) {
			return;
		}
		
		// Create a temporary layer group with all detour layers
		const tempGroup = L.featureGroup();
		this.detours[warningId].forEach(layer => {
			// We need to create a temp clone because the actual layer might be hidden
			const clone = L.geoJSON(layer.toGeoJSON());
			clone.addTo(tempGroup);
		});
		
		// Get bounds and zoom
		const bounds = tempGroup.getBounds();
		if (bounds.isValid()) {
			this.map.fitBounds(bounds, {
				padding: [30, 30],
				maxZoom: 16
			});
		}
	}

	/**
	 * Adjust the map bounds to fit all visible layers
	 * @returns {void}
	 */
	adjustMapBounds() {
		const bounds = this.getAllLayersBounds();
		if (bounds && bounds.isValid()) {
			this.map.fitBounds(bounds);
		}
	}

	/**
	 * Get bounds for a specific layer by name
	 * @param {string} layerName - Name of the layer to get bounds for
	 * @returns {L.LatLngBounds|null} - Bounds of the layer or null if invalid/not found
	 */
	getLayerBounds(layerName) {
		const layer = this.getLayer(layerName);
		if (!layer) {
			return null;
		}

		const bounds = L.latLngBounds();
		let hasValidGeometry = false;

		layer.eachLayer((sublayer) => {
			// Handle different geometry types
			if (sublayer.getBounds) {
				// For polygons, polylines, etc.
				bounds.extend(sublayer.getBounds());
				hasValidGeometry = true;
			} else if (sublayer.getLatLng) {
				// For markers, circles, etc.
				bounds.extend(sublayer.getLatLng());
				hasValidGeometry = true;
			} else if (sublayer.getLatLngs) {
				// For some multigeometries
				bounds.extend(sublayer.getLatLngs());
				hasValidGeometry = true;
			}
		});

		return hasValidGeometry ? bounds : null;
	}

	/**
	 * Get bounds that encompass all layers in the map
	 * @returns {L.LatLngBounds|null} - Combined bounds of all layers or null if no valid layers
	 */
	getAllLayersBounds() {
		const bounds = L.latLngBounds();
		let hasLayers = false;

		Object.values(this.layerGroups).forEach((layerGroup) => {
			if (layerGroup.getLayers().length > 0) {
				hasLayers = true;
				layerGroup.eachLayer((layer) => {
					if (layer.getBounds) {
						bounds.extend(layer.getBounds());
					}
				});
			}
		});

		return hasLayers ? bounds : null;
	}

	/**
	 * Get a layer by its name
	 * @param {string} layerName - Name of the layer to retrieve
	 * @returns {L.LayerGroup|null} - Layer group or null if not found
	 */
	getLayer(layerName) {
		return this.layerGroups[layerName] || null;
	}

	/**
	 * Toggle visibility of a layer by name
	 * @param {string} layerName - Name of the layer to toggle
	 * @param {boolean} visible - Whether the layer should be visible
	 * @returns {void}
	 */
	toggleLayer(layerName, visible) {
		const layer = this.getLayer(layerName);
		if (layer) {
			if (visible) {
				this.map.addLayer(layer);
			} else {
				this.map.removeLayer(layer);
			}

			// Also toggle children
			this.toggleChildLayers(layerName, visible);
		}
	}

	/**
	 * Toggle visibility of all child layers
	 * @param {string} parentName - Name of the parent layer
	 * @param {boolean} visible - Whether the child layers should be visible
	 * @returns {void}
	 */
	toggleChildLayers(parentName, visible) {
		const children = this.categoryHierarchy[parentName] || [];
		children.forEach((childName) => {
			this.toggleLayer(childName, visible);
		});
	}
}
