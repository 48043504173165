import L from "leaflet";
import "leaflet/dist/leaflet.css";
import LayerManager from "./LayerManager";
import LayerFilterControl from "../controls/LayerFilterControl";

export default class MapEngine {
	constructor(container, config = {}) {
		this.container =
			typeof container === "string"
				? document.getElementById(container)
				: container;

		this.config = config;
		this.map = null;
		this.layerManager = null;

		this.init();
	}

	init() {
		this.setupMarkerIcons();
		this.createMap();
		this.addTileLayer();
		this.fixLeafletPaneStyling();

		// Initialize layer manager first so it's available to the controls
		this.layerManager = new LayerManager(this.map, this.config);

		// Add standard controls if configured
		this.addControls();

		// Load GeoJSON data if provided
		if (this.config.geoJsonData) {
			this.loadGeoJSON(this.config.geoJsonData);
		}
	}

	/**
	 * Configure Leaflet marker icon paths
	 */
	setupMarkerIcons() {
		// Make sure L is imported/defined
		if (!L || !L.Icon || !L.Icon.Default) {
			console.error("Leaflet not properly loaded when configuring icons");
			return;
		}

		// Override default icon paths
		const iconConfig = this.config.markerIcons || {};

		// Use the provided icon URLs or fallback to defaults
		const iconUrl =
			iconConfig.iconUrl || "/dist/images/icons/marker-icon.png";
		const iconRetinaUrl =
			iconConfig.iconRetinaUrl || "/dist/images/icons/marker-icon-2x.png";
		const shadowUrl =
			iconConfig.shadowUrl || "/dist/images/icons/marker-shadow.png";

		// Fix Leaflet's icon image path issue
		delete L.Icon.Default.prototype._getIconUrl;

		// Set the default icon with absolute URLs
		L.Icon.Default.mergeOptions({
			iconUrl: iconUrl,
			iconRetinaUrl: iconRetinaUrl,
			shadowUrl: shadowUrl,

			iconSize: iconConfig.iconSize || [25, 41],
			iconAnchor: iconConfig.iconAnchor || [12, 41],
			popupAnchor: iconConfig.popupAnchor || [1, -34],
			shadowSize: iconConfig.shadowSize || [41, 41],
		});
	}

	createMap() {
		// Extract Leaflet-specific options from config
		const leafletOptions = this.config.leafletOptions || {};

		// Set some reasonable defaults based on our config
		const defaultOptions = {
			// Disable default zoom control - we'll add it separately if needed
			zoomControl: false,
			// Set center and zoom from our config
			center: this.config.initialView || [0, 0],
			zoom: this.config.initialZoom || 10, // !IMPORTAMT: Currently, this does nothing since the LayerManager sets the bounds to fit all layers.
		};

		// Merge defaults with user-provided options
		const mapOptions = { ...defaultOptions, ...leafletOptions };

		// Create the map with the combined options
		this.map = L.map(this.container.id || this.container, mapOptions);
	}

	addTileLayer() {
		const tileLayerOptions = this.config.tileLayerOptions || {};
		const defaultOptions = {
			attribution:
				this.config.attribution || "© OpenStreetMap contributors",
		};

		const options = { ...defaultOptions, ...tileLayerOptions };

		L.tileLayer(
			this.config.tileLayer ||
				"https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
			options,
		).addTo(this.map);
	}

	fixLeafletPaneStyling() {
		const mapPane = this.map.getPane("mapPane");
		if (mapPane) {
			mapPane.classList.remove("leaflet-pane");
		}
	}

	addControls() {
		const controls = this.config.controls || {};

		// Add zoom control if configured
		if (controls.zoom) {
			L.control
				.zoom({
					position: controls.zoomPosition || "topleft",
					...(controls.zoomOptions || {}),
				})
				.addTo(this.map);
		}

		// Add scale control if configured
		if (controls.scale) {
			L.control
				.scale({
					position: controls.scalePosition || "bottomleft",
					...(controls.scaleOptions || {}),
				})
				.addTo(this.map);
		}

		// Add custom layer filter control if we have the pre-rendered HTML
		if (this.config.layerFilterHtml) {
			this.addLayerFilterControl();
		}
	}

	addLayerFilterControl() {
		const layerFilterControl = new LayerFilterControl({
			position: "topright",
			layerManager: this.layerManager,
			renderedHtml: this.config.layerFilterHtml,
			filterTitle: this.config.filterTitle || "Map Layers",
		});

		this.map.addControl(layerFilterControl);
	}

	loadGeoJSON(data) {
		this.layerManager.loadGeoJSON(data);
	}

	on(event, callback) {
		this.map.on(event, callback);
		return this;
	}

	// Public API
	fitBounds(bounds) {
		this.map.fitBounds(bounds);
		return this;
	}

	/**
	 * Fit the map view to display a specific layer
	 * @param {string} layerName - Name of the layer to fit the view to
	 * @returns {MapEngine} - For method chaining
	 */
	fitLayer(layerName) {
		const bounds = this.layerManager.getLayerBounds(layerName);

		if (bounds && bounds.isValid()) {
			this.map.fitBounds(bounds, {
				padding: [20, 20], // Add padding for better visibility
			});
		} else {
			console.warn(`Could not determine bounds for layer "${layerName}"`);
		}

		return this;
	}

	fitAllLayers() {
		const bounds = this.layerManager.getAllLayersBounds();
		if (bounds && bounds.isValid()) {
			this.map.fitBounds(bounds);
		}
		return this;
	}

	/**
	 * Show a detour for a specific warning
	 * @param {string} warningId - ID of the warning to show detour for
	 * @param {boolean} [zoomToDetour=true] - Whether to zoom to the detour
	 * @returns {boolean} - Whether the detour was successfully shown
	 */
	showDetour(warningId, zoomToDetour = true) {
		if (this.layerManager) {
			return this.layerManager.toggleDetour(warningId, zoomToDetour, true);
		}
		return false;
	}

	/**
	 * Hide a detour for a specific warning
	 * @param {string} warningId - ID of the warning to hide detour for
	 * @returns {boolean} - Whether the detour was successfully hidden
	 */
	hideDetour(warningId) {
		if (this.layerManager) {
			return this.layerManager.toggleDetour(warningId, false, false);
		}
		return false;
	}

	/**
	 * Toggle a detour's visibility for a specific warning
	 * @param {string} warningId - ID of the warning to toggle detour for
	 * @param {boolean} [zoomToDetour=true] - Whether to zoom to the detour if shown
	 * @returns {boolean} - New visibility state
	 */
	toggleDetour(warningId, zoomToDetour = true) {
		if (this.layerManager) {
			return this.layerManager.toggleDetour(warningId, zoomToDetour);
		}
		return false;
	}

	/**
	 * Zoom to a specific detour
	 * @param {string} warningId - ID of the warning to zoom to detour
	 */
	zoomToDetour(warningId) {
		if (this.layerManager) {
			this.layerManager.zoomToDetour(warningId);
		}
	}
}
